<template>
  <div>
    <div class="row mb-3">
      
      <div class="col-md-2">
        <label>Range Type</label>
        <select
          v-model="rangeData"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="customData" selected>Custom</option>
          <option value="monthlyData">Monthly</option>
          <!-- <option value="yearlyData">Yearly</option> -->
        </select>
      </div>
      <div
        v-if="rangeData === 'customData'"
        class="col-md-2 justify-content-end"
      >
        <label>Start Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="startDateData"
          :format="momentFormatStartDateData"
          type="date"
          lang="en"
          :name="'startDate'"
          :placeholder="'Start Date'"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData === 'customData'"
        class="col-md-2 justify-content-end"
      >
        <label>End Date</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="endDateData"
          :format="momentFormatEndDateData"
          type="date"
          lang="en"
          :name="'endDate'"
          :placeholder="'End Date'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div
        v-if="rangeData === 'monthlyData'"
        class="col-md-2 justify-content-end"
      >
        <label>Month</label>
        <select
          v-model="monthData"
          class="form-control"
          name="created_by"
          data-live-search="true"
          placeholder="Range"
          @change="fecthData"
        >
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
      <div v-if="rangeData === 'monthlyData' || rangeData === 'yearlyData'" class="col-md-2 justify-content-end">
        <label>Year</label>
        <!-- <input v-model="year" type="number" class="form-control" placeholder="2020" @keyup="fecthData"> -->
        <date-picker
          v-model="yearData"
          type="year"
          :format="formatYearData"
          :name="'yearData'"
          :placeholder="'Year'"
          @input="fecthData"
        >
        </date-picker>
      </div>
      <div class="col-md-2">
        <label>Jobfile Type</label>
        <select
          v-model="jobfileTypeData"
          class="form-control"
          name="jobfileType"
          data-live-search="true"
          placeholder="Jobfile Type"
        >
          <option value="All" selected>All</option>
          <option value="1">Export</option>
          <option value="2">Import</option>
          <option value="3">Domestic</option>
        </select>
      </div>
      <div class="col-md-2">
        <label>Sales</label>
        <!-- <select v-model="marketingData" class="form-control" data-live-search="true" placeholder="Choose an Sales" multiple required>
          <option value="All" selected>All</option>
          <option v-for="(isi, key) in listMarketingByPermissions" :key="key" :value="isi.id_user">
            {{isi.name_user}}
          </option>
        </select> -->
        <multiselect
          id="marketing"
          v-model="marketingData"
          :options="marketingList" 
          :multiple="true" 
          :close-on-select="false" 
          :clear-on-select="false" 
          :preserve-search="true" 
          placeholder="Choose a Marketing"
          :custom-label="(opt) => opt.name_user"
          track-by="id_user" 
          :preselect-first="true"
        >
           </multiselect>
        <!-- <v-select v-model="marketingData" v-can="'CRM_ADMIN_ENABLE'" :options="listMarketingByPermissions" label="name_user" placeholder="Choose a Marketing">
          <template slot="marketingData" slot-scope="marketingData">
              {{ marketingData.name_user }}
          </template>
        </v-select>
        {{ marketingList }} -->
      </div>
      <div class="col-md-2">
        <label>Status</label>
        <select
          v-model="jobfileTypeTransaction"
          class="form-control"
          name="typeTransaction"
          data-live-search="true"
          placeholder="Jobfile Type"
        >
          <option value="All" selected>All</option>
          <option value="1">Open</option>
          <option value="2">In Progress</option>
          <option value="3">Closed</option>
        </select>
      </div>
      

      
      
    </div>

    <div class="row">
      <div class="col-md-8" style="align-content: center; display: flex"> </div>
        <div class="col-md-4" style="display: flex; justify-content: end">
          <!-- <label class="mt-3">Search</label> -->
            <input
              v-model="filterText2"
              type="text"
              class="form-control form-control-sm ml-2 mt-2"
              placeholder="Search . . ."
              @keyup="doFilter2"
            />
        <div>
          <b-dropdown
            id="dropdown-1"
            right
            variant="success"
            class="m-md-2"
            no-caret
          >
            <template v-slot:button-content>
              Preview
              <i class="fa fa-caret-down ml-2"></i>
            </template>
            <b-dropdown-item @click="doFilterMultipleKeywords"
              ><i class="fa fa-edit mr-2"></i>Filter Data</b-dropdown-item
            >
            <b-dropdown-item @click="exportData"
              ><i class="fa fa-edit mr-2"></i>Export Excel</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
    <!-- <h4 class="header-title">List Quotation</h4> -->
    <vue-element-loading
      :active="isLoadingTable"
      spinner="bar-fade-scale"
      color="#FF6700"
    />
    <!-- {{ fields }} -->
    <!-- Table -->
    <div class="table-responsive">
      <vuetable
        ref="vuetable"
        :key="index"
        :per-page="perPage"
        :fields="fields"
        :api-url="apiUrl"
        :api-mode="true"
        :http-options="httpOptions"
        :show-sort-icons="true"
        :append-params="moreParams"
        pagination-path
        class="table table-centered table-striped"
        data-path="data"
        @vuetable:loading="loadingTable"
        @vuetable:load-success="loadTableSuccess"
        @vuetable:load-error="loadTableFailed"
        @vuetable:pagination-data="onPaginationData"
      >
        <div slot="items" slot-scope="props">
          <a href="javascript:void(0);" class="action-icon" style="font-size:12px;" @click="showItems(props.rowData, props.rowIndex)">
            Item

            <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
                fill="#FFA800" />
            </svg>
          </a>
        </div>
      </vuetable>
    </div>
    <!-- end table -->

    <!-- pagination -->
    <div class="row">
      <div class="col-md-5">
        <div class="row no-gutters">
          <div class="col-lg-3">
            <div class="form-group float-lg-left">
              <select
                v-model="perPage"
                class="form-control form-control-sm"
                @change="onChangeTableLength"
              >
                <option v-for="opt in lengthMenu" :key="opt" :value="opt">
                  {{ opt }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-8">
            <vuetable-pagination-info
              ref="paginationInfo"
              class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              "
            >
            </vuetable-pagination-info>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-7">
        <vuetable-pagination
          ref="pagination"
          :css="css.pagination"
          class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          "
          @vuetable-pagination:change-page="onChangePage"
        >
        </vuetable-pagination>
      </div>
    </div>
    <!-- end pagination -->

    <!-- modal items -->
    <modal name="modal-items" height="auto" >
			<div class="row justify-content-center">
				<div class="col-md-12">
					<div class="card" style="">
						<div class="card-body">
              <div class="modal-header">
                <h1 class="header-title"> List Items </h1> 

                <button type="button" class="close" aria-label="Close" @click="hideModal">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
							<!-- <div class="card border"> -->
								<!-- <div class="card-body" style="padding:0px !important;"> -->
										<div class="row">
											<div class="col-md-12" style="white-space: pre-line">
                        
                      <div class="table-responsive" style="margin-bottom:0px !important;">
                        <DataTable class="table" showGridlines  :value="resultQuery" :paginator="true" :rows="5" 
                            paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                            >
                            <template header>
                                <div class="table-header d-flex justify-content-end mt-2 mr-2 mb-2" style="">
                                        <input class="form-control w-50 " type="text" v-model="searchQuery" placeholder="Search Item" />
                                                    

                                </div>
                            </template>
                            <Column field="no" header="NO" headerClass = "for-header" :styles="{ width: '1%' }"></Column>
                            <Column field="item_name" header="ITEM" headerClass = "for-header" :styles="{ width: '50%' }"></Column>
                            <Column field="amount" header="AMOUNT" headerClass = "for-header" :styles="{ width: '49%' }"></Column>
                        </DataTable>
                      </div>
                      
											</div>
										</div>
								<!-- </div>
							</div> -->
              <div class="modal-footer">
                <button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideModal">Cancel</button>
              </div>
						</div>
					</div>
				</div>
			</div>
		</modal>
  </div>
</template>
<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Multiselect from 'vue-multiselect'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import DataTable from 'primevue/datatable';
// import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import Column from 'primevue/column';
// import ColumnGroup from 'primevue/columngroup';
import Swal from 'sweetalert2' 
  
import DatePicker from 'vue2-datepicker'
import {FilterMatchMode} from 'primevue/api';
// import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'

var url = '/report-ebilling'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    url = '/report-ebilling'
  }
}


export default {
  
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker,
    Multiselect,
    // VuePerfectScrollbar,
    DataTable,
    Column,
    // ColumnGroup
    //  mixins: [VuetableFieldMixin],
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    options: {
      type: Object,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      moreParams: {},
      moreParamsItem: {},
      filterText: '',
      filterText2: '',
      searchQuery: '',
      deleteData: {},
      isLoadingTable: false,
      httpOptions: {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        },
      },
      filters: null,
      loading:true,
      apiUrl: this.$store.state.rootApi + url,
      urlPdf: '#',
      urlCi: process.env.VUE_APP_CI_URL,
      lengthMenu: [10, 25, 50, 100],
      perPage: 10,
      perPageItems:5,
      fields: [
        
        // {
        //   name: 'no',
        //   title: 'No.',
        // },
        {
          name: 'shipment_no',
          title: 'SHIPMENT No'
        },
        {
          name: 'sell_date',
          title: 'Invoice Create Date',
        },
        {
          name: 'kind_of_shipment',
          title: 'Type Job',
        },
        {
          name: 'month',
          title: 'Month',
        },
        {
          name: 'eta',
          title: 'ETA',
        },
        {
          name: 'etd',
          title: 'ETD',
        },
        {
          name: 'pol',
          title: 'POL',
        },
        {
          name: 'pod',
          title: 'POD',
        },
        {
          name: 'commodity',
          title: 'COMMODITY',
        },
        {
          name: 'container',
          title: 'CONTAINERS No.',
        },
        // {
        //   name: 'sell_date',
        //   title: 'INVOICE CREATE DATE',
        // },
        {
          name: 'due_date',
          title: 'DUE DATE',
        },
        {
          name: 'type_selling',
          title: 'TYPE',
        },
        {
          name: 'inv_corp',
          title: 'INV CORP',
        },
        {
          name: 'no',
          title: 'NO',
        },
        {
          name: 'customer',
          title: 'CUSTOMER',
        },
        {
          name: 'currency',
          title: 'CURRENCY',
        },
        {
          name: 'amount',
          title: 'AMOUNT',
        },
        {
          name: 'corporate',
          title: 'CORPORATE',
        },
        {
          name: 'items',
          title: 'ITEMS',
          width: '20%'
        }
        
      ],
      css: {
        pagination: {
          activeClass: 'btn-success text-white',
          pageClass: 'btn btn-sm',
          linkClass: 'btn btn-sm',
          icons: {
            prev: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
          },
        },
      },
      page:1,
      pageSize:5,
      momentFormatStartDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.startDateData).format('YYYY/MM/DD') : ''
        },
      },
      momentFormatEndDateData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.endDateData).format('YYYY/MM/DD') : ''
        },
      },
      year: null,
      createdBy: null,
      statusData: 'All',
      startDateData: null,
      endDateData: null,
      branchData: [],
      list: [],
      rangeData: 'customData',
      marketingData:[
      //   {
      //   id_user: 'All',
      //   name_user: 'All'
      // }
      ],
      marketingPermission:[],
      topCustomerData: '15',
      compareData: false,
      isManualTopCustData: false,
      isAutoTopCustData: true,
      monthData: '01',
      startDateCalculated:'',
      endDateCalculated:'',

      yearData: null,
      formatYearData: {
        // this.dataPayload.year
        stringify: (date) => {
          return date ? moment(this.yearData).format('YYYY') : ''
        },
      },
      isLoading: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      jobfileTypeData: 'All',
      jobfileTypeTransaction: 'All',
      customerData: [],
      sellItems : [],
      itemList : [],
      isiItems : [],
      fieldItems : [
        {
          'name' : 'item_name',
          'title' : 'ITEM'
        },
        {
          'name' : 'amount',
          'title' : 'AMOUNT'
        }
      ],
      listAfterFilter: [],
      marketingList:[
            {
        id_user: 'All',
        name_user: 'All'
      }
      ]
    }
  },
  computed: {
    itemsWithSno() {
      return this.isiItems.map((d, index) => ({ ...d, sno: index + 1 }))
    },

    ...mapState('user', ['listMarketingByBranchData1', 'listUser', 'listMarketingByPermissions']),
    ...mapState('item', ['listItem', 'listItemNonInventory', 'listItemBank', 'dataItem']),

    pageTableData() {
      return this.isiItems.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
    },
    resultQuery(){

      if(this.searchQuery){
        return this.isiItems.filter((item)=>{
          return this.searchQuery.toLowerCase().split(' ').every(v => item.item_name.toLowerCase().includes(v))
        })
        }else{

          return this.isiItems;
        }
    }
  },
  created() {
      this.initFilters();
  },
  mounted() {
    this.getListUser()
    this.getMarketingByPermission()
    // this.marketingList.push(this.listMarketingByPermissions)
    this.getItems()
  },
  methods: {
    ...authMethods,
    errorAlertValidation: function (message = 'Terjadi Kesalahan') {
      this.$swal({
        title: message,
        text:'Contact your admin',
        type: 'error',
        timer: '2000',
        toast: true,
        position: 'bottom-end',
      })
    },
    loginByToken() {
      console.log('login by token')
    },
    ...mapActions('item', ['getItemByPpn', 'getItemByCategory', 'getItemNonInventoryDiffVar', 'getItemByCategoryBank', 'getItem']),

    ...mapActions('user', [
      'getListUser',
      'getListMarketingByBranchData1',
      'getListMarketingByBranchData2',
      'getListMarketingByPermissions',
    ]),
    initFilters() {
        this.filters = {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            // 'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}
            
        }
    },
    getItems(){
      let config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
          }
      axios({
          url: this.$store.state.rootApi + '/item_category?item_category=1',
          config,
          method: 'GET',
        }).then((response) => {
          console.log(response, 'list item')
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i];
            // console.log(element)
            this.itemList.push(element)
                
            
          } 

          
        })
    },
    showItems(rowData, rowIndex){
      this.$modal.show('modal-items')
      this.getItemByCategory({
            itemCategory: 1
          });
      this.sellItems = []
      this.sellItems.push(rowData)

      



        let test = []
        this.isiItems = []
        for (let i = 0; i < this.sellItems.length; i++) {
          const element = this.sellItems[i];
          for (let a = 0; a < this.itemList.length; a++) {
            const el = this.itemList[a];

              for (const [key, value] of Object.entries(element.sell_items)) {
                  console.log(key, value.id);

                    if(el.item_number === value.id){
                      test.push({
                        "item_name" : el.item_name,
                        "amount" : this.formatRupiah((value.price).toString(), element.currency),
                        "item_number" : el.item_number
                      })
                    }
              }
          }
        }
        // let test = [];
        // this.listAfterFilter = []
        // for (let j = 0; j < this.itemList.length; j++) {
          
        //   const el = this.itemList[j];
        //   this.listAfterFilter.push(el)
          
        // }
        // console.log(this.listAfterFilter, ' isi testing')

        // for( var i=this.listAfterFilter.length - 1; i>=0; i--){
        //   for( var j=0; j<test.length; j++){
        //       if(this.listAfterFilter[i] && (this.listAfterFilter[i].item_number === test[j].item_number)){
        //           this.listAfterFilter.splice(i, 1);
        //       }
        //   }
        // }

        // console.log(this.listAfterFilter, 'after filter')
        // console.log(test, 'before push')
        for (let i = 0; i < test.length; i++) {
          const element = test[i];
          this.isiItems.push({
            "no" : i +1,
            "item_name" : element.item_name,
            "amount" : element.amount,
            "item_number" : element.item_number
          })
        }
        // for (let i = 0; i < this.listAfterFilter.length; i++) {
        //   var nom = this.isiItems.length
        //   const element = this.listAfterFilter[i];
        //   this.isiItems.push({
        //     "no" :  nom + 1,
        //     "item_name" : element.item_name,
        //     "amount" : '-',
        //     "item_number" : element.item_number
        //   })
          
        // }


        console.log(this.isiItems, 'testttt')
    },
    formatRupiah(angka, prefix){
      // console.log(angka, 'THIS ANGKAA')
      angka = angka.replace(/\./g, ',')
			let numberString = angka.replace(/[^,\d]/g, '').toString()
      // console.log(numberString, 'THIS ANGKAA 2')
			let split   		= numberString.split(',')
			let sisa     		= split[0].length % 3
			let rupiah     		= split[0].substr(0, sisa)
			let ribuan     		= split[0].substr(sisa).match(/\d{3}/gi)
 
			// tambahkan titik jika yang di input sudah menjadi angka ribuan
			if(ribuan){
				let separator = sisa ? '.' : '';
				rupiah += separator + ribuan.join('.');
			}
 
			rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
			return prefix === undefined ? rupiah : (rupiah ? prefix + ' ' + rupiah : '');
		},
    hideModal(){
      this.isLoadingTable = false
      this.$modal.hide('modal-items')
      console.log('tsettt')
    },
    getMarketingByBranchData(permission) {
      try {
        // let request = []
        // let strBranch = 'id_branch[]=' + localStorage.getItem('default_branch')
        // this.branchData.forEach((data, idx) => {
        //   if (idx === 0) {
        //     strBranch += 'id_branch[]=' + data.id_branch
        //     request.push(strBranch, permission)
        //   } else {
        //     strBranch += '&id_branch[]=' + data.id_branch
        //     request.push(strBranch, permission)
        //   }
        // })
        let permissionName = '&permissionName[] =' + permission
        console.log(permissionName,'permissionName');
        this.getListMarketingByPermissions(permissionName)
          .then((res) => {
            console.log(res, 'test return permission')
            // this.user_id = res.result[1].id
            for (let i = 0; i < res.result.length; i++) {
              const element = res.result[i];
              this.marketingPermission.push(element.id_user)
              this.marketingList.push({
                id_user : element.id_user,
                name_user: element.name_user
              })
              // this.marketingData.push({
              //   id_user : element.id_user,
              //   name_user: element.name_user
              // })
              console.log(element)
            }
            console.log(this.marketingPermission, 'marketing permission')
          })
          // .catch((err) => {
          //   console.log(err)
          // })
      } catch (error) {
        console.log(error)
      }
    },

    getMarketingByPermission(){
      for (
        let i = 0;
        i < localStorage.getItem('userPermissions').length;
        i++
      ) {
        let enable = 'REPORT_EBILLING_ENABLE'
        let view = 'REPORT_EBILLING_VIEW'
        let user = 'REPORT_EBILLING_USERS'
        let parseUserPermission = localStorage.getItem('userPermissions')
        if ( 
          parseUserPermission.includes(enable) && parseUserPermission.includes(view) && parseUserPermission.includes(user)
        ) {
            this.getMarketingByBranchData(user);
            break
        }
            else if(parseUserPermission.includes(enable) && parseUserPermission.includes(view)){
              this.getMarketingByBranchData(view);
              break
            }
          else if(parseUserPermission.includes(user)){
              this.getMarketingByBranchData(user);
              break
            }
          else if(parseUserPermission.includes(enable)){
            this.getMarketingByBranchData(enable);
            break
          }
      }
    },

    doFilter() {
      this.onFilterSet(this.filterText)
    },
    doFilter2() {
      this.onFilterSet2(this.filterText2)
      // console.log(this.branchData1, 'THIS BRANCH DATA 1');
    },
    doFilterItem() {
      // this.onFilterSetItem(this.searchQuery)

      // console.log(this.branchData1, 'THIS BRANCH DATA 1');
    },
    doFilterMultipleKeywords() {
      this.onFilterSetMultipleKeywords('filter')
    },
    exportData() {
      this.onFilterSetMultipleKeywords('export')
    },
    /**
     * Table Length Change
     *
     * @return  {[type]}  [return description]
     */
    onChangeTableLength() {
      // this.$refs.vuetable.reload()
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    /**
     * Pagination page change
     */
    onChangePage(page) {
      this.$refs.vuetable.changePage(page)
    },
    /**
     * Pagination info and pagination show
     */
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    /**
     * [onFilterSet description]
     *
     * @param   {[type]}  filterText  [filterText description]
     *
     * @return  {[type]}              [return description]
     */
    onFilterSet(filterText) {
      this.moreParams = {
        search: filterText,
      }
      this.$nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterSet2(filterText2) {
      console.log(filterText2)
      let strMarketing = []
      console.log(this.marketingData, 'isi marketing data')
      if(this.marketingData.length === 0){
        this.marketingData = [
          {
                id_user:'All',
                name_user: 'All'
              }
          ]
      }
      this.marketingData.forEach((data, idx) => {
        // if(data.id == 0){
        //   data.id = ''
        // }
        if(data.id_user === 'All'){
          for (let i = 0; i < this.marketingPermission.length; i++) {
            const element = this.marketingPermission[i];
            strMarketing.push(element)
          }
        }else{
          strMarketing.push(data.id_user)

        }
      })
      setTimeout(() => {
        this.moreParams = {
          branch: localStorage.getItem('default_branch'),
          company: localStorage.getItem('id_company'),
          jobfileType: this.jobfileTypeData,
          startDate: this.startDateCalculated,
          endDate: this.endDateCalculated,
          TypeTransaction: this.jobfileTypeTransaction,
          sales:strMarketing,
          perPage: 10,
          filter: true,
          search: filterText2,
        }

        console.log(this.moreParams, 'THIS DATA SEARCH')
        this.$nextTick(() => this.$refs.vuetable.refresh())
      }, 0)
    },
    onFilterSetItem(searchQuery) {
      console.log(searchQuery)
      
      setTimeout(() => {
        this.moreParamsItem = {
          
          perPage: 5,
          search: searchQuery,
        }

        console.log(this.moreParamsItem, 'THIS DATA SEARCH')
        this.$nextTick(() => this.$refs.vuetable2.refresh())
      }, 0)
    },
    onFilterSetMultipleKeywords(filterText) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      if (this.rangeData === 'customData') {
        tempStartDateData = new Date(
          new Date(this.startDateData.toString()).getFullYear(),
          new Date(this.startDateData.toString()).getMonth(),
          new Date(this.startDateData.toString()).getDate() + 1
        )
          .toISOString()
          .split('T')[0]

        tempEndDateData = new Date(
          new Date(this.endDateData.toString()).getFullYear(),
          new Date(this.endDateData.toString()).getMonth(),
          new Date(this.endDateData.toString()).getDate() + 1
        )
          .toISOString()
          .split('T')[0]
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            parseInt(this.monthData.toString()) - 1,
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            parseInt(this.monthData.toString()),
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            0,
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            12
          )
            .toISOString()
            .split('T')[0]
        }
      }

      let strMarketing = []
      console.log(this.marketingData, 'isi marketing data')
      if(this.marketingData.length === 0){
        this.marketingData = [
          {
                id_user:'All',
                name_user: 'All'
              }
          ]
      }
      this.marketingData.forEach((data, idx) => {
        // if(data.id == 0){
        //   data.id = ''
        // }
        if(data.id_user === 'All'){
          for (let i = 0; i < this.marketingPermission.length; i++) {
            const element = this.marketingPermission[i];
            strMarketing.push(element)
          }
        }else{
          strMarketing.push(data.id_user)

        }
      })
      console.log(this.marketingData, 'marketing data')

      // let strCustomer = []
      // this.customerData.forEach((data, idx) => {
      //   strCustomer.push(data.id)
      // })

      if (this.jobfileType === '') {
        this.errorAlertValidation('Jobfile Type is required!')
        return false;
      } else if (this.statusData === '') {
        this.errorAlertValidation('Status is required!')
        return false;
      } else if (this.rangeData === '') {
        this.errorAlertValidation('Range is required!')
        return false;
      } else if (this.rangeData !== '') {
        if (this.rangeData === 'customData') {
          if (this.startDateData === '' || this.startDateData == null) {
            this.errorAlertValidation('Start Date is required!')
            return false;
          } else if (this.endDateData2 === '' || this.endDateData == null) {
            this.errorAlertValidation('End Date is required!')
            return false;
          } else {
            if(this.diffDays(new Date(this.startDateData), new Date(this.endDateData)) > 32) {
              this.errorAlertValidation('The data displayed / exported must not be more than 30 days')
              return false;
            } else {
              this.$nextTick(() => this.$refs.vuetable.refresh())
            }
          }
        } else if (this.rangeData === 'monthlyData') {
          if (this.monthData === '' || this.monthData == null) {
            this.errorAlertValidation('Month is required!')
            return false;
          } else if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
            return false;
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        } else if (this.rangeData === 'yearlyData') {
          if (this.yearData === '' || this.yearData == null) {
            this.errorAlertValidation('Year is required!')
            return false;
          } else {
            this.$nextTick(() => this.$refs.vuetable.refresh())
          }
        }
      }
      this.startDateCalculated = tempStartDateData
      this.endDateCalculated = tempEndDateData
      let sales = []
        for (let i = 0; i < strMarketing.length; i++) {
          const element = strMarketing[i];
          sales.push(
            'sales[]=' + element
          )
          
        }
       let test = sales.join("&");
      if (filterText === 'filter') {
        this.moreParams = {
          branch: localStorage.getItem('default_branch'),
          company: localStorage.getItem('id_company'),
          jobfileType: this.jobfileTypeData,
          startDate: tempStartDateData,
          endDate: tempEndDateData,
          TypeTransaction: this.jobfileTypeTransaction,
          sales:strMarketing,
          perPage: 10,
          filter: true,
        }
        // console.log(this.moreParams, 'THIS PARAMSSS')
        // console.log(filterText)
        this.$nextTick(() => this.$refs.vuetable.refresh())
       
      } else {
        
        
        // console.log(test,' isi sales')
        var idCompany = localStorage.getItem('id_company')
        var branch = localStorage.getItem('default_branch')
        // let config = {
        //   headers: {
        //     Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            
        //   },
        // }
        // axios({
        //   url: this.$store.state.rootApi + '/report-ebilling?jobfileType='+ this.jobfileTypeData +'&TypeTransaction='+this.jobfileTypeTransaction+'&startDate='+ tempStartDateData+'&endDate='+tempEndDateData+'&branch='+localStorage.getItem('default_branch')+'&company='+localStorage.getItem('id_company')+'&'+test+'&filter=true&get=true',
        //   config,
        //   method: 'GET',
        // }).then((response) => {
        //   console.log(response, 'lyuhuuuuu isi ebilinngg')
          try {
            window.location.href = `${this.$store.state.rootApi}/export-excel-ebilling?jobfileType=${this.jobfileTypeData}&TypeTransaction=${this.jobfileTypeTransaction}&startDate=${tempStartDateData}&endDate=${tempEndDateData}&branch=${branch}&company=${idCompany}&${test}&filter=true`
            
          } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something wrong!',
              })
            // location.reload(); 
            console.log(error, 'export excel')
            return Promise.reject(error)
          }

          
        // }).catch(
        //   (error) => {
        //     console.log('Show error notification!')
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Something wrong!',
        //       })
        //     // location.reload(); 
        //     console.log(error, 'export excel')
        //     return Promise.reject(error)
        //   }
        // )

        // console.log(tempStartDateData)
      }
    },
    
    /**
     * Show Table Loading
     *
     * @return  {[type]}  [return description]
     */
    loadingTable() {
      this.isLoadingTable = true
    },

    /**
     * Hide Loading if Success
     *
     * @return  {[type]}  [return description]
     */
    loadTableSuccess() {
      this.isLoadingTable = false
    },
    /**
     * Hide Loading if Failed
     *
     * @return  {[type]}  [return description]
     */
    loadTableFailed(error) {
      console.log(error)

      this.isLoadingTable = false
      // this.$swal({
      //   title: 'Terjadi Kesalahan',
      //   text:'testing',
      //   type: 'error',
      //   timer: '2000',
      //   toast: true,
      //   position: 'bottom-end',
      // })

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something wrong!',
      })
     location.reload();

      // }
    },

    formatDate(type) {
      let tempStartDateData = ''
      let tempEndDateData = ''

      // console.log(this.monthData1.toString())
      // console.log(new Date(this.yearData2.toString()).getFullYear())

      if (this.rangeData === 'customData') {
        tempStartDateData = this.startDateData
        tempEndDateData = this.endDateData
      } else if (this.rangeData === 'monthlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth(),
            2
          )
            .toISOString()
            .split('T')[0]
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear(),
            new Date(this.monthData.toString()).getMonth() + 1,
            1
          )
            .toISOString()
            .split('T')[0]
        }
      } else if (this.rangeData === 'yearlyData') {
        if (this.yearData === null) {
          tempStartDateData = null
          tempEndDateData = null
        } else {
          tempStartDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() + '/01/01'
          )
          tempEndDateData = new Date(
            new Date(this.yearData.toString()).getFullYear() +
              '/12/' +
              new Date(
                new Date(this.yearData.toString()).getFullYear(),
                12,
                0
              ).getDate()
          )
        }
      }

      if (tempStartDateData === null || tempEndDateData === null) {
        return ''
      }
      return (
        this.monthNames[tempStartDateData.getMonth()] +
        ' ' +
        tempStartDateData.getFullYear() +
        ' - ' +
        this.monthNames[tempEndDateData.getMonth()] +
        ' ' +
        tempEndDateData.getFullYear()
      )
    },

    fecthData() {
      console.log('OK')
    },

    diffDays(date, otherDate) {
      return (Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24)) + 1)
    }
  },
}
</script>
<style >
.table-header {
    align-items: right;
}

.for-header {
  background-color: #51C1AD !important;
  color: #fff !important;
}

</style>